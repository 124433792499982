import { logException } from './error-reporting';

const displayConnectionErrorAlert = () => {
    document.getElementById('connection-error-alert-container').style.display = 'block';
};

const hideConnectionErrorAlert = () => {
    document.getElementById('connection-error-alert-container').style.display = 'none';
};

// eslint-disable-next-line no-unused-vars
const displayGeneralErrorAlert = () => {
    document.getElementById('general-error-alert-container').style.display = 'block';
};

const hideGeneralErrorAlert = () => {
    document.getElementById('general-error-alert-container').style.display = 'none';
};

const isConnectionError = (error) => error.message === 'Connection aborted';

export const displayConnectionErrorHandler = {
    displayError: (error) => {
        if (isConnectionError(error)) {
            displayConnectionErrorAlert();
        } else {
            logException(error);
            // displayGeneralErrorAlert(); dočasně zakomentované kvůli false positive hlášení z Naji, https://sentry.rtsoft.cz/organizations/sentry/issues/16698
        }
    },
    hideError: () => {
        hideConnectionErrorAlert();
        hideGeneralErrorAlert();
    },
};
