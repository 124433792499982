import React, { forwardRef } from 'react';
import { trans } from '../../../../utils/translation';

// results komponenta

// eslint-disable-next-line
const Result = forwardRef(({ result, detailUrl }, ref) => (
    <div className="whisperer-head-wrapper-result__results" ref={ref}>
        {result ? (
            <>
                {/* kategorie */}

                {result.categories && result.categories.length ? (
                    <>
                        {result.categories.map((category, index) => (
                            <a
                                key={index}
                                className="whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--category"
                                href={category.url}
                                title={category.label}
                            >
                                {category.label}
                            </a>
                        ))}
                    </>
                ) : null}

                {/* produkty */}

                {result.products && result.products.length ? (
                    <>
                        <h4 className="whisperer-head-wrapper-result__results_header">
                            <span>{trans().whisperer.products}</span>
                        </h4>
                        {result.products.map((product, index) => {
                            const className = `whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--${
                                product.image ? 'product' : 'category'
                            }`;

                            return (
                                <a key={index} className={className} href={product.url} title={product.label}>
                                    {!!product.image && <img src={product.image} alt={product.label} />}
                                    <span>{product.label}</span>
                                </a>
                            );
                        })}
                    </>
                ) : null}

                {/* články */}

                {result.articles && result.articles.length ? (
                    <>
                        <h4 className="whisperer-head-wrapper-result__results_header">
                            <span>{trans().whisperer.sitesContent}</span>
                        </h4>
                        {result.articles.map((articles, index) => (
                            <a
                                key={index}
                                className="whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--article"
                                href={articles.url}
                                title={articles.label}
                            >
                                {articles.label}
                            </a>
                        ))}
                    </>
                ) : null}

                {/* poradna */}

                {result.advices && result.advices.length ? (
                    <>
                        <h4 className="whisperer-head-wrapper-result__results_header">
                            <span>{trans().whisperer.advices}</span>
                        </h4>
                        {result.advices.map((advices, index) => (
                            <a
                                key={index}
                                className="whisperer-head-wrapper-result__results_item whisperer-head-wrapper-result__results_item--article"
                                href={advices.url}
                                title={advices.label}
                            >
                                {advices.label}
                            </a>
                        ))}
                    </>
                ) : null}

                {/* všechny výsledky */}

                {(result.articles && result.articles.length) ||
                (result.advices && result.advices.length) ||
                (result.products && result.products.length) ||
                (result.categories && result.categories.length) ? (
                    <div className="whisperer-head-wrapper-result__show_all">
                        <a href={detailUrl} className="btn btn--primary">
                            {trans().whisperer.allResults}
                        </a>
                    </div>
                ) : (
                    <div className="whisperer-head-wrapper-result__empty">{trans().whisperer.noItems}</div>
                )}
            </>
        ) : (
            <div className="spinner" />
        )}
    </div>
));

export default Result;
