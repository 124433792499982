import '../../../polyfills';

export function openTooltip(container, content) {
    let tooltip = container.querySelector(':scope > .tooltip');

    if (tooltip === null) {
        tooltip = document.createElement('div');
        tooltip.className = 'tooltip';

        container.appendChild(tooltip);

        container.addEventListener('mouseleave', (event) => {
            const tooltip = event.target.querySelector(':scope > .tooltip');

            if (tooltip === null) {
                return;
            }

            closeTooltip(tooltip);
        });
    }

    tooltip.innerHTML = content;

    tooltip.setAttribute('show', '');
}

function closeTooltip(tooltip) {
    tooltip.removeAttribute('show');
}
