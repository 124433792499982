import '../../../polyfills';

export function initMenu() {
    document.querySelectorAll('#menu-main .menu-trigger').forEach((element) => {
        element.addEventListener('click', (event) => {
            // Pokud nejde o mobil, ignorujeme
            if (window.matchMedia('(min-width: 576px)').matches) {
                return;
            }

            event.preventDefault();

            const trigger = event.currentTarget;
            const target = trigger.closest('.menu-main__item--has-submenu');

            if (target === null) {
                return;
            }

            if (target.classList.contains('open')) {
                target.classList.remove('open', 'open-lowest');
                closeNestedSubmenu(target);

                const parentMenu = target.parentNode.closest('.menu-main__item--has-submenu.open');
                if (parentMenu === null) {
                    return;
                }

                parentMenu.classList.add('open-lowest');
            } else {
                closeNestedSubmenu(target.parentNode);
                target.classList.add('open', 'open-lowest');
                document.body.classList.add('menu-open');

                bindMenuCloser();

                const parentMenu = target.parentNode.closest('.menu-main__item--has-submenu.open-lowest');
                if (parentMenu === null) {
                    return;
                }
            }
        });
    });

    document.querySelectorAll('.menu-main-switch').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.stopPropagation();

            // const trigger = event.currentTarget;
            const target = document.getElementById('menu-main');

            if (target.classList.contains('open')) {
                closeMenu();
            } else {
                target.classList.add('open', 'open-lowest');
                // trigger.classList.add('open');

                bindMenuCloser();
                closeHeaderCart();
                closeHeaderLogin();
                closeMenuShort();
                closeSearchForm();

                document.body.classList.add('menu-open');
            }
        });
    });

    document.querySelectorAll('.header-cart-widget-switch').forEach((element) => {
        element.addEventListener('click', (event) => {
            // Pokud nejde o mobil, ignorujeme
            if (window.matchMedia('(min-width: 576px)').matches) {
                return;
            }

            event.preventDefault();

            // const trigger = event.currentTarget;
            const target = document.getElementById('header-cart-widget');

            if (target.classList.contains('open')) {
                closeHeaderCart();
            } else {
                target.classList.add('open');

                bindHeaderCartCloser();
                closeMenu();
                closeHeaderLogin();
                closeMenuShort();
                closeSearchForm();

                document.body.classList.add('menu-open');
            }
        });
    });

    document.querySelectorAll('.header-login-switch').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            // const trigger = event.currentTarget;
            const target = document.getElementById('header-login-widget');

            if (target.classList.contains('open')) {
                closeHeaderLogin();
            } else {
                target.classList.add('open');
                // trigger.classList.add('open');

                bindHeaderLoginCloser();
                closeMenu();
                closeHeaderCart();
                closeMenuShort();
                closeSearchForm();

                document.body.classList.add('menu-open');
            }
        });
    });

    // Fixace loginu pri kliknuti do nej
    document.querySelectorAll('#header-login-widget .login-widget__canvas').forEach((element) => {
        element.addEventListener('click', () => {
            // Pokud jde o mobil, ignorujeme
            if (!window.matchMedia('(min-width: 576px)').matches) {
                return;
            }

            // const trigger = event.currentTarget;
            const target = document.getElementById('header-login-widget');

            target.classList.add('open');

            bindHeaderLoginCloser();
            closeMenu();
            closeHeaderCart();
            closeMenuShort();
            closeSearchForm();

            document.body.classList.add('menu-open');
        });
    });

    document.querySelectorAll('.menu-short-switch').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            // const trigger = event.currentTarget;
            const target = document.getElementById('menu-short');

            if (target.classList.contains('open')) {
                closeMenuShort();
            } else {
                target.classList.add('open');
                // trigger.classList.add('open');

                bindMenuShortCloser();
                closeMenu();
                closeHeaderCart();
                closeHeaderLogin();
                closeSearchForm();
            }
        });
    });

    document.querySelectorAll('.search-menu-switch').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            // const trigger = event.currentTarget;
            const target = document.getElementById('search-menu');

            if (target.classList.contains('open')) {
                closeSearchForm();
            } else {
                target.classList.add('open');
                // trigger.classList.add('open');

                bindSearchMenuCloser();
                closeMenu();
                closeHeaderCart();
                closeHeaderLogin();
                closeMenuShort();

                document.body.classList.add('menu-open');
            }
        });
    });
}

function closeNestedSubmenu(element) {
    element.querySelectorAll('.has-submenu.open').forEach((element) => {
        element.classList.remove('open', 'open-lowest');
    });
}

function bindMenuCloser() {
    document.removeEventListener('click', menuCloser);
    document.addEventListener('click', menuCloser);
}

function bindHeaderCartCloser() {
    document.removeEventListener('click', headerCartCloser);
    document.addEventListener('click', headerCartCloser);
}

function bindHeaderLoginCloser() {
    document.removeEventListener('click', headerLoginCloser);
    document.addEventListener('click', headerLoginCloser);
}

function bindMenuShortCloser() {
    document.removeEventListener('click', menuShortCloser);
    document.addEventListener('click', menuShortCloser);
}

function bindSearchMenuCloser() {
    document.removeEventListener('click', searchFormCloser);
    document.addEventListener('click', searchFormCloser);
}

function menuCloser(event) {
    if (event.target.closest('#menu-main .has-submenu.open, #menu-main.open') !== null) {
        return;
    }

    closeMenu();
}

function closeMenu() {
    document.querySelectorAll('#menu-main, .menu-main-switch').forEach((element) => {
        element.classList.remove('open', 'open-lowest');
    });

    closeNestedSubmenu(document.getElementById('menu-main'));

    document.body.classList.remove('menu-open');

    document.removeEventListener('click', menuCloser);
}

function searchFormCloser(event) {
    if (event.target.closest('#search-menu') !== null) {
        return;
    }

    closeSearchForm();
}

function closeSearchForm() {
    document.getElementById('search-menu').classList.remove('open');

    document.body.classList.remove('menu-open');

    document.removeEventListener('click', searchFormCloser);
}

function headerCartCloser(event) {
    if (event.target.closest('#header-cart-widget') !== null) {
        return;
    }

    closeHeaderCart();
}

function closeHeaderCart() {
    document.getElementById('header-cart-widget').classList.remove('open');

    document.body.classList.remove('menu-open');

    document.removeEventListener('click', headerCartCloser);
}

function headerLoginCloser(event) {
    if (event.target.closest('#header-login-widget') !== null) {
        return;
    }

    closeHeaderLogin();
}

function closeHeaderLogin() {
    document.getElementById('header-login-widget').classList.remove('open');

    document.body.classList.remove('menu-open');

    document.removeEventListener('click', headerLoginCloser);
}

function menuShortCloser(event) {
    if (event.target.closest('#menu-short') !== null) {
        return;
    }

    closeMenuShort();
}

function closeMenuShort() {
    const target = document.getElementById('menu-short');
    if (target !== null) {
        target.classList.remove('open');
    }

    document.removeEventListener('click', menuShortCloser);
}
