export const initReCaptcha = (selector = null) => {
    // map of <htmlID>: <widget/client ID>
    const clientIDs = {};

    // Register reCAPTCHAs
    window['g_onRecaptchaLoad'] = function () {
        document.querySelectorAll('.g-recaptcha').forEach((element) => {
            clientIDs[element.id] = grecaptcha.render(
                element,
                {
                    size: 'invisible',
                    badge: 'bottomright',
                },
                true
            );
        });
    };

    const formSelector = selector ? selector : 'form';

    // Register form submit event listeners
    document.addEventListener('DOMContentLoaded', () => {
        addEventListeners(formSelector);

        window.naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            addEventListeners('#' + event.detail.snippet.id + ' ' + formSelector);
        });
    });
};

const addEventListeners = (formSelector) => {
    document.querySelectorAll(formSelector).forEach((element) => {
        // Register callback only if form contains recaptcha
        if (element.querySelector('.g-recaptcha')) {
            element.addEventListener('submit', formSubmitEventCallback);
        }
    });
};

const formSubmitEventCallback = (event) => {
    const form = event.currentTarget;
    if (Nette.validateForm(form, true)) {
        const submitBtn = form['nette-submittedBy'];
        event.preventDefault();

        // Překrytí elementu spinnerem
        if (form.dataset.najaSpinnerSelector && window.uiBlockerInitialized) {
            window.uiBlockerInitialized.block(form.dataset.najaSpinnerSelector);
        }

        // execute only reCAPTCHAs in submitted form
        grecaptcha.execute().then((token) => {
            // Odkrytí spinneru z elementu - následné volání submitForm ho kdyžtak zase ihned překryje
            if (form.dataset.najaSpinnerSelector && window.uiBlockerInitialized) {
                window.uiBlockerInitialized.unblock(form.dataset.najaSpinnerSelector);
            }

            // Put token into hidden field
            document.querySelectorAll('.g-recaptcha-response').forEach((element) => {
                element.textContent = token;
            });

            // Remove submit event listener, otherwise it will call itself infinitely
            form.removeEventListener('submit', formSubmitEventCallback);

            // Submit the form again
            if (window.naja && form.dataset.ajax) {
                window.naja.uiHandler.submitForm(form);
            } else if (submitBtn) {
                submitBtn.click();
            } else {
                const event = document.createEvent('HTMLEvents');
                event.initEvent('submit', true, false);
                form.dispatchEvent(event);
            }
        });
    }
};
