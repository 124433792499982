import '../../../polyfills';

export function initCollapse() {
    const collapsibleElements = document.getElementsByClassName('collapsible__header');

    for (let i = 0; i < collapsibleElements.length; i++) {
        collapsibleElements[i].addEventListener('click', function () {
            const target = this.closest('.collapsible');
            target.classList.toggle('collapsible--open');
        });
    }
}
