export const refreshGrid = (gridId) => {
    const grid = document.getElementById(gridId);
    const event = new Event('rtsoft-grid-force-update');

    grid.dispatchEvent(event);
};

export const createGridJsActionEvent = (gridId, rowParameters) => {
    const event = new Event('rtsoft-grid-js-action-event', {
        bubbles: true,
        cancelable: true,
    });

    const data = {
        gridId,
        rowParameters,
    };

    Object.keys(data).forEach((key) => {
        event[key] = data[key];
    });

    return event;
};
