import '../../../polyfills';

export function initDropdowns(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.dropdown__trigger').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();

            const trigger = event.currentTarget;
            const target = trigger.closest('.dropdown');

            if (target === null) {
                return;
            }

            const openned = target.classList.contains('dropdown--open');

            closeDropdowns();

            if (openned) {
                return;
            }

            target.classList.add('dropdown--open');

            bindDropdownCloser();
        });
    });

    // Navazani fixace otevreneho dropdownu po kliku dovnitr neho
    document.querySelectorAll(scopePrefix + '.dropdown__menu').forEach((element) => {
        element.addEventListener('click', (event) => {
            const trigger = event.currentTarget;
            const target = trigger.closest('.dropdown');

            closeDropdowns();

            if (target === null || target.classList.contains('dropdown--open')) {
                return;
            }

            target.classList.add('dropdown--open');

            bindDropdownCloser();
        });
    });
}

function bindDropdownCloser() {
    document.removeEventListener('click', dropdownCloser);
    document.addEventListener('click', dropdownCloser);
}

function dropdownCloser(event) {
    if (event.target.closest('.dropdown--open') !== null) {
        return;
    }

    closeDropdowns();
}

function closeDropdowns() {
    document.querySelectorAll('.dropdown--open').forEach((element) => {
        element.classList.remove('dropdown--open');
    });

    document.removeEventListener('click', dropdownCloser);
}
