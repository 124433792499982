import '../../polyfills';
import { initSubmitOnChange } from '../shared/form/submit-on-change';
import { initMenu } from './layout/menu';
// import { initSearchMenu } from './searchMenu';
import { initSwitchable } from './components/switchable';
import { initDismissable } from './components/dismissable';
import { initDropdowns } from './components/dropdown';
import { initCollapse } from './components/collapse';
import { initWhisperer } from './react/whisperer/whisperer';
import { initIncrementableInputs } from './components/incrementable-input';
import naja from 'naja';
import { LoaderExtension } from '../../library/naja/naja-spinners';
import { LiveForm, Nette } from 'live-form-validation';
import { uiBlocker } from '../../library/ui-blocker/ui-blocker';
import { initReCaptcha } from './form/recaptcha';
import { initFooterMenu } from './components/footer-menu';
import '../../../appwork/libs/toastr/toastr';
import { initSentryFromWebConfig } from '../../utils/sentry';
import { displayConnectionErrorHandler } from '../../utils/xhr-display-connection-error-handler';
// import { initSliders } from './components/slider';
import { initGtmEventsIfGtmActive } from './gtm/gtm';
import { initInfoBar } from './components/info-bar';
import { initSmoothScroll } from './components/smooth-scroll';
import { initSpinnerSelector } from '../../library/ui-blocker/spinner-selector';

initSentryFromWebConfig();

window.LiveForm = LiveForm;
window.Nette = Nette;
window.naja = naja;
Nette.initOnLoad();

initReCaptcha();

LiveForm.setOptions({
    messageErrorClass: 'form-input-message form-input-message--danger',
    messageErrorPrefix: '',
});

document.addEventListener('DOMContentLoaded', () => {
    // inicializueme menu
    initMenu();
    // initSearchMenu(); // @TODO asi lepsi reseni, ale nutno poladit pripadne se zbytkem menu.js

    // inicializueme dropdowns
    initDropdowns();

    // inicializueme prepinatelne
    initSwitchable();

    // inicializueme prepinatelne
    initDismissable();

    // inicializujeme GTM udalosti
    initGtmEventsIfGtmActive();

    // inicializujeme collapse toggler
    initCollapse();

    // inicializace sbalitelnych footer menu
    initFooterMenu();

    initSmoothScroll();

    // inicializueme našeptávač
    initWhisperer(
        document.getElementById('whisperer-head-wrapper'), // id formjuláře, který našeptávač spouští
        document.getElementById('whisperer-head-wrapper-result'), // container pro výsledky,
        600, // debounce
        3, // min chars
        document.getElementById('whisperer-head-wrapper').dataset.whisperQueryUrl,
        document.getElementById('whisperer-head-wrapper').dataset.whisperDetailUrl
    );

    const uiBlockerInitialized = uiBlocker();
    window.uiBlockerInitialized = uiBlockerInitialized;

    // naja.initialize.bind(naja);
    naja.registerExtension(new LoaderExtension('#loader', uiBlockerInitialized, displayConnectionErrorHandler));

    if (naja.initialized === false) {
        naja.initialize();
    }

    initSpinnerSelector(uiBlockerInitialized);

    naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        initSwitchable('#' + event.detail.snippet.id);
        initDropdowns('#' + event.detail.snippet.id);
        initDismissable('#' + event.detail.snippet.id);
        initSubmitOnChange('#' + event.detail.snippet.id);
        initGtmEventsIfGtmActive('#' + event.detail.snippet.id);
        initSmoothScroll('#' + event.detail.snippet.id);
        initSpinnerSelector(uiBlockerInitialized, '#' + event.detail.snippet.id);
    });

    // inicializace YT video elementů

    // hydrateYoutubeElements();

    // inicializace inkrementovanych inputu pro pridani do kosiku
    initIncrementableInputs();

    // ui blocker - ukázka použití

    // setTimeout(() => {
    //     const elements = document.body.querySelectorAll('.bgimg-product-box__content-wrapper > .btn--primary')
    //     for(let i = 0; i < elements.length; i++){
    //         uiBlocker.block(elements[i])
    //     }
    //     const blockedInstance = uiBlocker.block('.bgimg-article-box__image')
    //     setTimeout(() => {
    //         blockedInstance.unblock()
    //         for(let i = 0; i < elements.length; i++){
    //             uiBlocker.unblock(elements[i])
    //         }
    //     }, 5000)
    // }, 3000)

    // zavěsíme na všechny slidery
    // initSliders('.bs-slider');

    initSubmitOnChange();

    initInfoBar();
});
