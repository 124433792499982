export const setCookie = (key, value, expiration) => {
    const expires = `expires=${expiration}`;

    document.cookie = `${key}=${value};${expires};path=/`;
};

export const getCookie = (key) => {
    const splitCookies = decodeURIComponent(document.cookie).split(';');

    for (let i = 0; i < splitCookies.length; i++) {
        let splitCookie = splitCookies[i];

        while (splitCookie.charAt(0) === ' ') {
            splitCookie = splitCookie.substring(1);
        }

        if (splitCookie.indexOf(`${key}=`) === 0) {
            return splitCookie.substring(`${key}=`.length, splitCookie.length);
        }
    }

    return '';
};
