import { getCookie, setCookie } from '../../../library/cookie/cookie';

const INFO_BAR_LOCAL_STORAGE_KEY_PREFIX = 'info-bar-';
const INFO_BAR_OPEN_CLASS = 'info-bar--open';
const INFO_BAR_DATASET_ID_KEY = 'infoBarId';

const getInfoBarId = (infoBarElement) => infoBarElement.dataset[INFO_BAR_DATASET_ID_KEY];

const hideInfoBarElement = (infoBarElement) => {
    const isOpened = infoBarElement.classList.contains(INFO_BAR_OPEN_CLASS);

    if (!isOpened) {
        return;
    }

    const infoBarId = getInfoBarId(infoBarElement);

    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 3);

    infoBarElement.classList.remove(INFO_BAR_OPEN_CLASS);
    setCookie(`${INFO_BAR_LOCAL_STORAGE_KEY_PREFIX}${infoBarId}`, true, expiryDate.toUTCString());
};

const showInfoBarElement = (infoBarElement) => {
    infoBarElement.classList.add(INFO_BAR_OPEN_CLASS);
};

export const initInfoBar = () => {
    const infoBarElement = document.getElementById('info-bar');
    const infoBarCancelElement = document.getElementById('info-bar-close');

    if (!infoBarCancelElement || !infoBarElement) {
        return;
    }

    const infoBarId = getInfoBarId(infoBarElement);

    const isInfoBarClosed = getCookie(`${INFO_BAR_LOCAL_STORAGE_KEY_PREFIX}${infoBarId}`);

    if (isInfoBarClosed) {
        hideInfoBarElement(infoBarElement);
        return;
    }

    showInfoBarElement(infoBarElement);

    infoBarCancelElement.addEventListener('click', () => {
        hideInfoBarElement(infoBarElement);
    });
};
