import '../../../polyfills';

export function initDismissable(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.dismissable__trigger').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();

            const trigger = event.currentTarget;
            const target = trigger.closest('.dismissable');

            if (target === null) {
                return;
            }

            target.remove();
        });
    });
}
