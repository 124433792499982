import { initSentry } from '../library/sentry/sentry';
import { getJsData } from './js-data';

export const initSentryFromWebConfig = () => {
    const jsData = getJsData();

    if (!!jsData.s && !!jsData.s.d) {
        const sentryConfig = jsData.s;

        initSentry(sentryConfig.d, sentryConfig.e, sentryConfig.r, sentryConfig.tsr, sentryConfig.ijs, sentryConfig.wu);
    }
};
