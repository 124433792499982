export function initSpinnerSelector(uiBlockerInitialized, scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';
    // Reaguje na klik a zůstane do překreslení stránky!!!
    // Používat např. jen pro odkazy, u formulářů pokud dojde k chybě, tak se to zůstane točit!
    document
        .querySelectorAll(scopePrefix + '[data-spinner-selector-on-click]')
        .forEach((el) =>
            el.addEventListener('click', () => uiBlockerInitialized.block(el.dataset.spinnerSelectorOnClick))
        );
}
