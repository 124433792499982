import * as Sentry from '@sentry/browser';
// import { Integrations } from '@sentry/tracing';

export const initSentry = (dsn, environment, release, tracesSampleRate, ignoreErrors, whitelistUrls) => {
    Sentry.init({
        dsn,
        environment,
        release,
        tracesSampleRate,
        ignoreErrors,
        whitelistUrls,
        // integrations: [new Integrations.BrowserTracing()],
    });
};
