import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Result from './result';
import { createDebouncedFunc, makeXhrRequest } from '../../../../global-helpers';

// whisperer react app

const App = ({ container, debounceTimeout, minChars, queryUrl, detailUrl }) => {
    // state

    const [shown, setShown] = useState(false);
    const [result, setResult] = useState(null);
    const resultElement = useRef();

    // helpers

    const getSearchPhrase = () => container.querySelector('input[type="search"]').value;

    const getDetailUrl = () => detailUrl + '?query=' + encodeURIComponent(getSearchPhrase());

    const submitHandler = (event) => {
        event ? event.preventDefault() : null;
        const value = getSearchPhrase();
        if (value) {
            if (!event && value.length < minChars) {
                return;
            }

            setShown(true);
            setResult(false);

            //  dummy call -> pak bude napojeno na "api"
            const maxResultsHeight = window.innerHeight - resultElement.current.getBoundingClientRect().top;
            resultElement.current.style.maxHeight = `${maxResultsHeight - 50}px`;

            makeXhrRequest('GET', queryUrl, null, { query: value }, ({ body }) => {
                setResult(JSON.parse(body));
            });
        }
    };

    const typeHandler = createDebouncedFunc(() => {
        submitHandler();
    }, debounceTimeout);

    // lifecycle

    useEffect(() => {
        container.querySelector('form').addEventListener('submit', submitHandler);
        container.querySelector('input[type="search"]').addEventListener('keyup', typeHandler);

        return () => {
            container.querySelector('form').removeEventListener('submit', submitHandler);
            container.querySelector('input[type="search"]').removeEventListener('keyup', typeHandler);
        };
    }, []);

    useEffect(() => {
        if (!shown) {
            return;
        }

        const clickHandler = () => {
            setShown(false);
        };
        document.body.addEventListener('click', clickHandler);

        return () => {
            document.body.removeEventListener('click', clickHandler);
        };
    }, [shown]);

    // output

    return shown ? <Result ref={resultElement} result={result} detailUrl={getDetailUrl()} /> : null;
};

// boostrap (připojení do DOMu)

export const initWhisperer = (container, destination, debounceTimeout, minChars, queryUrl, detailUrl) => {
    if (container && destination) {
        ReactDOM.render(
            <App
                container={container}
                debounceTimeout={debounceTimeout || 500}
                minChars={minChars || 3}
                queryUrl={queryUrl}
                detailUrl={detailUrl}
            />,
            destination
        );
    } else {
        console.log('invalid DOM element/s for mounting');
    }
};
