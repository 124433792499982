import { getJsData } from '../../../utils/js-data';
import {
    createGtmAddToCartEvent,
    createGtmRemoveFromCartEvent,
    createGtmProductClickEvent,
    // createGtmCheckoutOptionEvent,
} from '../../../library/gtm/gtm';

const getGtmConfigData = () => getJsData().gtm;

const fireGtmEvent = (gtmEvent) => {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(gtmEvent);
};

const initAddToCartGtmEvent = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.gtm-add-to-cart').forEach((element) => {
        bindGtmCartManipulationEvent(element, 'add');
    });
};

const initRemoveFromCartGtmEvent = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.gtm-remove-from-cart').forEach((element) => {
        bindGtmCartManipulationEvent(element, 'remove');
    });
};

const initProductClickGtmEvent = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.gtm-product-click').forEach((element) => {
        element.addEventListener('click', () => {
            // Nacteme data z atributu
            const productData = getProductData(element);

            if (productData === null) {
                return;
            }

            fireGtmEvent(createGtmProductClickEvent([productData]));
        });
    });
};

const bindGtmCartManipulationEvent = (element, action) => {
    const gtmEventsCreators = {
        add: createGtmAddToCartEvent,
        remove: createGtmRemoveFromCartEvent,
    };

    if (!gtmEventsCreators[action]) {
        return;
    }

    element.addEventListener('click', () => {
        // Nacteme data z atributu
        const productData = getProductData(element);

        if (productData === null) {
            return;
        }

        // Defaultni hodnota navyseni je 1
        let quantity = 1;

        // Mame zadane prime mnozstvi kolik zmenit
        if (element.dataset.gtmCartQuantity) {
            quantity = Number.parseFloat(element.dataset.gtmCartQuantity);
        } else if (element.dataset.gtmCartQuantityId) {
            // Najdeme hodnotu patricneho inputu mnozstvi
            const quantityInput = document.getElementById(element.dataset.gtmCartQuantityId);

            if (quantityInput !== null) {
                quantity = Number.parseFloat(quantityInput.value);
            }
        }

        if (Number.isNaN(quantity)) {
            return;
        }

        // Pridame mnozstvi
        productData.quantity = quantity;

        delete productData.list;
        delete productData.position;

        fireGtmEvent(gtmEventsCreators[action]([productData], getGtmConfigData().currency));
    });
};

const getProductFromProducts = (productCode) => {
    const products = getJsData().products.filter((product) => product.item_id === productCode);

    return products.length > 0 ? products[0] : null;
};

const getProductData = (element) => {
    const { code, list, position } = JSON.parse(element.dataset.gtmProductData);

    const product = getProductFromProducts(code);
    if (!product) {
        return null;
    }

    return { ...product, list, position };
};

export const initGtmEvents = (scope) => {
    initAddToCartGtmEvent(scope);
    initRemoveFromCartGtmEvent(scope);
    initProductClickGtmEvent(scope);
    // initCheckoutOptionGtmEvent(scope);
};

export const initGtmEventsIfGtmActive = (scope) => {
    if (getGtmConfigData().enabled) {
        initGtmEvents(scope);
    }
};
