const UI_BLOCKER_CSS_CLASS = 'ui-blocker';

export const uiBlocker = () => ({
    blockedCollection: [],
    _createBlocker: function (el) {
        const rect = el.getBoundingClientRect();
        const blocker = document.createElement('div');
        blocker.classList.add(UI_BLOCKER_CSS_CLASS);
        blocker.setAttribute(
            'style',
            `left:${rect.left}px;top:${rect.top + (window.scrollY || window.pageYOffset)}px;width:${
                rect.width
            }px;height:${rect.height}px`
        );
        const blockerSpinner = document.createElement('div');
        blockerSpinner.classList.add('spinner');
        blocker.appendChild(blockerSpinner);
        document.body.appendChild(blocker);
        this.blockedCollection.push({ el: el.getAttribute('id') || el, blocker });
    },
    _getElement: function (element) {
        return typeof element === 'string' ? document.body.querySelectorAll(element) : element;
    },
    block: function (element) {
        const el = this._getElement(element);

        if (Object.prototype.isPrototypeOf.call(NodeList.prototype, el)) {
            if (el.length === 0) {
                throw Error('Calling BLOCK on non-existing element');
            }

            el.forEach((element) => this._createBlocker(element));
        } else {
            if (!el) {
                throw Error('Calling BLOCK on non-existing element');
            }

            this._createBlocker(el);
        }

        return { unblock: () => this.unblock(el) };
    },
    unblock: function (element) {
        const el = this._getElement(element);
        const newBlockedCollection = [];
        const elementIds = [];

        if (Object.prototype.isPrototypeOf.call(NodeList.prototype, el)) {
            if (el.length === 0) {
                throw Error('Calling UNBLOCK on non-existing element');
            }

            el.forEach((element) => elementIds.push(element.getAttribute('id')));
        } else {
            if (!el) {
                throw Error('Calling UNBLOCK on non-existing element');
            }

            elementIds.push(el.getAttribute('id'));
        }

        this.blockedCollection.forEach((item) => {
            // U prvku prekreslenych AJAXem to nemusi byt isSameNode, i kdyz pro uzivatele je..
            // Je lepsi to skryt (i kdyby nahodou driv), nez to nechat viset tocit
            if (
                (typeof item.el !== 'string' && item.blocker) /* item.el.isSameNode(el)*/ ||
                elementIds.includes(item.el)
            ) {
                document.body.removeChild(item.blocker);
            } else {
                newBlockedCollection.push(item);
            }
        });
        this.blockedCollection = newBlockedCollection;
    },
    unblockAll: function () {
        this.blockedCollection.forEach((item) => {
            document.body.removeChild(item.blocker);
        });

        this.blockedCollection = [];

        // Vsechny elementy s classou ui-blocker odstranime
        const elements = document.getElementsByClassName(UI_BLOCKER_CSS_CLASS);

        for (const element of elements) {
            document.body.removeChild(element);
        }
    },
});
