import '../components/tooltip';
import { openTooltip } from './tooltip';

function handleOnIncrementableButtonClick(button, input, incrementButton, isIncrementing) {
    const incrementAmount =
        (isIncrementing ? 1.0 : -1.0) *
        parseFloat(button.getAttribute('data-' + (isIncrementing ? 'increase' : 'decrease') + '-amount'));

    try {
        const inputValue = parseFloat(input.value);
        if (isNaN(inputValue)) {
            throw new Error('Value in input is not a number!');
        }
        if (inputValue <= -1.0 * incrementAmount && !isIncrementing) {
            return;
        }

        const newAmount = inputValue + incrementAmount;

        handleDisabledClasses(input, incrementButton, newAmount);
        if (input.dataset.availableStock !== null && input.dataset.availableStock <= newAmount) {
            if (incrementButton.classList.contains('incrementable__control--disabled')) {
                openTooltip(incrementButton, input.dataset.notAvailableTitle);
            }

            if (input.dataset.availableStock < newAmount) {
                return;
            }
        }

        if (inputValue === newAmount) {
            return;
        }

        input.value = newAmount;
    } catch (err) {
        console.error(err);
    }
}

function handleDisabledClasses(input, incrementButton, newAmount) {
    if (input.dataset.availableStock !== null && input.dataset.availableStock <= newAmount) {
        if (!incrementButton.classList.contains('incrementable__control--disabled')) {
            incrementButton.classList.add('incrementable__control--disabled');
            incrementButton.title = input.dataset.notAvailableTitle;
        }
    } else if (incrementButton.classList.contains('incrementable__control--disabled')) {
        incrementButton.classList.remove('incrementable__control--disabled');
        incrementButton.title = '';
    }
}

export function initIncrementableInputs(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document
        .querySelectorAll(
            scopePrefix + 'button.incrementable__control--dec,' + scopePrefix + 'button.incrementable__control--inc'
        )
        .forEach((button) => {
            const input = button.parentElement.getElementsByTagName('input')[0];
            if (!input) {
                return;
            }
            const incrementButton = button.parentElement.querySelector('button.incrementable__control--inc');
            handleDisabledClasses(input, incrementButton, input.value);

            button.addEventListener('click', () => {
                handleOnIncrementableButtonClick(
                    button,
                    input,
                    incrementButton,
                    button.classList.contains('incrementable__control--inc')
                );
            });
        });
}
