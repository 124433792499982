export const createGtmEcommerceEvent = (event, ecommerceData) => ({
    event: event,
    ecommerce: ecommerceData,
});

export const createGtmAddToCartEvent = (productsData, currency) =>
    createGtmEcommerceEvent('add_to_cart', {
        currency: currency,
        items: productsData,
    });

export const createGtmRemoveFromCartEvent = (productsData, currency) =>
    createGtmEcommerceEvent('remove_from_cart', {
        currency: currency,
        items: productsData,
    });

export const createGtmProductClickEvent = (productsData) =>
    createGtmEcommerceEvent('view_item', {
        items: productsData,
    });
